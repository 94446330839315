<template>
  <div class="sign-up">
    <v-container>
      <v-row>
        <v-col>
          <SignUpComponent />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import SignUpComponent from '@/components/auth/SignUpComp.vue';

export default {
  name: 'SignUp',
  props: {
    user: String,
  },
  metaInfo: {
    title: 'Sign Up',
  },
  components: {
    SignUpComponent,
  },
};
</script>
