<template>
  <div class="sign-up">
    <v-card width="500">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="orange darken-2"
      ></v-progress-linear>
      <v-card-title>Sign up for an account</v-card-title>
      <v-card-text>
          <v-text-field
            type="email"
            color="orange"
            v-model="email"
            prepend-icon="mdi-account"
            placeholder="Email Address"
          ></v-text-field>

          <v-text-field
            v-model="password"
            color="orange"
            prepend-icon="mdi-lock"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-password"
            label="Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              block large dark
              color="orange"
              :loading="loading"
              :disabled="loading"
              @click="signUp"
            >Sign Up</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="error">
      <v-icon>mdi-alert-circle-outline</v-icon>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
// import { Auth } from 'aws-amplify';
// import store from '@/store/store';

export default {
  name: 'SignUp',
  data() {
    return {
      error: false,
      message: '',
      email: '',
      loading: false,
      password: '',
      show1: false,
      user: '',
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`), // eslint-disable-line
      },
    };
  },
  methods: {
    async signUp() {
      // this.loading = true;
      // try {
      //   const user = await Auth.signIn(this.email, this.password); // SignIn

      //   this.loading = false; // Loading complete

      //   this.$router.push('/'); // Route to home

      //   store.commit('user', user); // Push user into store

      //   console.log(user); // eslint-disable-line
      // } catch (error) {
      //   console.log('error signing in', error); // eslint-disable-line
      //   this.loading = false;
      //   this.error = true;
      //   this.message = error.message;
      // }
    },
  },
};
</script>
